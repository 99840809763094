import Arrow from '$icons/arrow-left.svg';
import { useScrollPosition } from '@n8tb1t/use-scroll-position/lib/useScrollPosition';
import React, { useState } from 'react';
import { useTranslation } from '~/shared/utils/translation';
import { useMenuScroll } from '../../hooks/useMenuScroll';
import { StyledIconButtonTop, StyledTopButtonOptionText } from './styled';

type Props = {
    small?: boolean;
    desktop?: boolean;
    textColor?: string;
    backgroundColor?: string;
    onClick?: () => void;
};

const defaultBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};
export const TopButton = (props: Props) => {
    const [hideOnScroll, setHideOnScroll] = useState(true);
    const { translate } = useTranslation();
    const { textColor, backgroundColor, onClick } = props;
    const { toTopButtonOffset } = useMenuScroll();

    useScrollPosition(
        ({ currPos }) => {
            const isShow = currPos.y > -800;
            if (isShow !== hideOnScroll) setHideOnScroll(isShow);
        },
        [hideOnScroll],
        undefined,
        false,
        300,
    );

    return hideOnScroll ? null : (
        <StyledIconButtonTop
            onClick={onClick || defaultBackToTop}
            textColor={textColor as string}
            backgroundColor={backgroundColor}
            offset={toTopButtonOffset}
        >
            <StyledTopButtonOptionText textColor={textColor}>
                {translate('navigation.topButton')}
            </StyledTopButtonOptionText>
            <Arrow />
        </StyledIconButtonTop>
    );
};
